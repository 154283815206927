import { ReactElement, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { 
  AbstractEventAnalyticsLabel, EventCategory, EventType,
  ExecutiveContactCategory, FetchReturn, User
} from '../../../store';
import { useForm } from 'react-hook-form';
import { EventLabelAbstractFormInput } from '../../EventAnalytics/Modals/EventLabelAbstractModal';
import { CabToggleChip } from '@CabComponents/CabToggleChip';
import colors from '../../../colors';
import { CabIcon } from '@CabComponents/CabIcon';
import { IoAddOutline } from 'react-icons/io5';
import { CabModal } from '@CabComponents/CabModal';
import { CabButton } from '@CabComponents/CabButton';
import CreateEventCategoryModal from '../../EventAnalytics/Modals/CreateEventCategoryModal/CreateEventCategoryModal';
import { PermissionError } from '../../../utils/permissionUtils';


interface Props {
  eventCategories: { [key: number]: EventCategory };
  eventTypes: { [key: number]: EventType };
  executiveContactCategories: { [key: number]: ExecutiveContactCategory };
  user: User | null | undefined;
  createLabel: (
    action: "event_type" | "event_category" | "contact_category", data: AbstractEventAnalyticsLabel, 
    callback: () => void) => Promise<FetchReturn<
      AbstractEventAnalyticsLabel, PermissionError> | undefined>;
  updateLabel: (
    action: "event_type" | "event_category" | "contact_category", 
    data: Partial<AbstractEventAnalyticsLabel> & Pick<AbstractEventAnalyticsLabel, 'id'>, callback: () => void) => 
  Promise<FetchReturn<AbstractEventAnalyticsLabel, PermissionError> | undefined>;
  saving: boolean;
  deleteLabel: (action: "event_type" | "event_category" | "contact_category", id: number, callback: () => void) => void;
}



export const OrganizationEventTags = ({
  eventCategories, eventTypes, executiveContactCategories, user, createLabel, updateLabel, saving, deleteLabel
}: Props): ReactElement => {

  const [selectedTag, setSelectedTag] = useState(-1);
  const [tagVariation, setTagVariation] = useState<'event_category'|'event_type'|'contact_category'>();
  const [openAddEditModal, setOpenAddEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const { control, reset, getValues, setValue, formState } = useForm<EventLabelAbstractFormInput>({
    defaultValues: {
      id: -1,
      name:  "",
      color: colors.black200,
      organization: user?.active_license.organization
    }
  });

  const modalTitleStr = selectedTag > 0 ? 'Edit' : 'Create';
  const buttonStr = selectedTag > 0 ? 'Save' : 'Create';
  const modalVariationStr = tagVariation === 'event_category' ? ' Event Category' : tagVariation === 'contact_category'
    ? ' Contact Category' : ' Event Type';

  const categories = Object.values(eventCategories);
  const contactCategories = Object.values(executiveContactCategories);
  const types = Object.values(eventTypes);

  const createNewCategory = () => {
    reset();
    setSelectedTag(-1);
    setTagVariation('event_category');
    setOpenAddEditModal(true);
  };

  const editCategory = (category: AbstractEventAnalyticsLabel) => {
    setSelectedTag(category.id);
    setValue('id', category.id);
    setValue('name', category.name);
    setValue('color', category.color);
    setTagVariation('event_category');
    setOpenAddEditModal(true);
  };

  const createNewContactCategory = () => {
    reset();
    setSelectedTag(-1);
    setTagVariation('contact_category');
    setOpenAddEditModal(true);
  };

  const editContactCategory = (category: AbstractEventAnalyticsLabel) => {
    setSelectedTag(category.id);
    setValue('id', category.id);
    setValue('name', category.name);
    setValue('color', category.color);
    setTagVariation('contact_category');
    setOpenAddEditModal(true);
  };

  const createNewType = () => {
    reset();
    setSelectedTag(-1);
    setTagVariation('event_type');
    setOpenAddEditModal(true);
  };

  const editType = (type: AbstractEventAnalyticsLabel) => {
    setSelectedTag(type.id);
    setValue('id', type.id);
    setValue('name', type.name);
    setValue('color', type.color);
    setTagVariation('event_type');
    setOpenAddEditModal(true);
  };

  const submitCleanup = () => {
    setOpenDeleteModal(false);
    setOpenAddEditModal(false);
    setSelectedTag(-1);
    setTagVariation(undefined);
    reset();
  };

  const labelSorter = (a:AbstractEventAnalyticsLabel, b: AbstractEventAnalyticsLabel) => {
    const nameA = a.name.toUpperCase();
    const nameB = b.name.toUpperCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  };

  const onConfirmClick = async () => {
    if (selectedTag < 0) {
      const res = await createLabel(tagVariation ?? 'event_category', getValues(), () => submitCleanup());
      if (res?.status === 400 && typeof res.data.detail === "string") {
        setErrorMessage(res.data.detail);
      }
    } else {
      const res = await updateLabel(tagVariation ?? 'event_category', getValues(), () => submitCleanup());
      if (res?.status === 400 && typeof res.data.detail === "string") {
        setErrorMessage(res.data.detail);
      }
    }
  };

  return (
    <>
      <Box display='flex' flexDirection='column'>
        <Box display='flex' flexDirection='column' gap={2} padding={1.25}>
          <Box display='flex' justifyContent='space-between'>
            <Typography variant='h5' fontWeight={700}>Event Category</Typography>
            <CabToggleChip
              label={<Box display='flex' alignItems='center'>
                <CabIcon Icon={IoAddOutline} sx={{fontSize: 20}} />
                New
              </Box>}
              chipColor={colors.black200}
              translateColor
              selected
              onClick={createNewCategory}
              sx={{marginLeft: '16px'}}
            />
          </Box>
          <Box display='flex' flexWrap='wrap' gap={1}>
            {categories.sort(labelSorter).map(category => {
              return <CabToggleChip
                key={category.id}
                label={category.name}
                chipColor={category.color}
                translateColor
                selected
                onClick={() => editCategory(category)}
              />;
            })}
          </Box>
        </Box>
        <Box borderBottom={1} marginTop={1} marginBottom={1} borderColor={colors.black200} width={'100%'}/>
        <Box display='flex' flexDirection='column' gap={2} padding={1.25}>
          <Box display='flex' justifyContent='space-between'>
            <Typography variant='h5' fontWeight={700}>Event Type</Typography>
            <CabToggleChip
              label={<Box display='flex' alignItems='center'>
                <CabIcon Icon={IoAddOutline} sx={{fontSize: 20}} />
                New
              </Box>}
              chipColor={colors.black200}
              translateColor
              selected
              onClick={createNewType}
              sx={{marginLeft: '16px'}}
            />
          </Box>
          <Box display='flex' flexWrap='wrap' gap={1}>
            {types.sort(labelSorter).map(type => {
              return <CabToggleChip
                key={type.id}
                label={type.name}
                chipColor={type.color}
                translateColor
                selected
                onClick={() => editType(type)}
              />;
            })}
          </Box>
        </Box>
        <Box borderBottom={1} marginTop={1} marginBottom={1} borderColor={colors.black200} width={'100%'}/>
        <Box display='flex' flexDirection='column' gap={2} padding={1.25}>
          <Box display='flex' justifyContent='space-between'>
            <Typography variant='h5' fontWeight={700}>Contact Relationship</Typography>
            <CabToggleChip
              label={<Box display='flex' alignItems='center'>
                <CabIcon Icon={IoAddOutline} sx={{fontSize: 20}} />
                New
              </Box>}
              chipColor={colors.black200}
              translateColor
              selected
              onClick={createNewContactCategory}
              sx={{marginLeft: '16px'}}
            />
          </Box>
          <Box display='flex' flexWrap='wrap' gap={1}>
            {contactCategories.sort(labelSorter).map(category => {
              return <CabToggleChip
                key={category.id}
                label={category.name}
                chipColor={category.color}
                translateColor
                selected
                onClick={() => editContactCategory(category)}
              />;
            })}
          </Box> 
        </Box>
      </Box>

      <CabModal
        open={openAddEditModal}
        onClose={() => setOpenAddEditModal(false)}
        closeIcon={true}
        title={modalTitleStr + modalVariationStr}
        actionButtons={
          <Box width='100%' display='flex' justifyContent='space-between'>
            {selectedTag > 0 && (
              <CabButton
                buttonType="tertiary"
                onClick={() => setOpenDeleteModal(true)}
              >
                Delete
              </CabButton>
            )}
            <Box gap={1} display='flex' width='100%' justifyContent='flex-end'>
              <CabButton
                buttonType="secondary"
                onClick={() => setOpenAddEditModal(false)}
              >
                Cancel
              </CabButton>
              <CabButton
                disabled={saving || !formState.isValid}
                onClick={onConfirmClick}
              >
                {buttonStr} {tagVariation === 'event_type' ? ' Type' : ' Category'}
              </CabButton>
            </Box>
          </Box>
        }
      >
        <CreateEventCategoryModal
          control={control}
        />
        {errorMessage &&
          <Typography color="error" sx={{width: "100%", marginTop: 2, marginLeft: 1}}>{errorMessage}</Typography>
        }
      </CabModal>

      <CabModal
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        isAlert={true}
        title={'Are you sure?'}
        noFullScreen={true}
        text={
          <Typography color={colors.black800}>
            {`Removing this ${tagVariation === 'event_type' ? ' type' : ' category'} will permanently remove it 
            from all events for `}<b>{'all users in your organization'}</b>{'. Are you sure you want to remove it?'}
          </Typography>
        }
        actionButtons={
          <>
            <CabButton buttonType='tertiary' color='primary' onClick={() => setOpenDeleteModal(false)}>
              No
            </CabButton>
            <CabButton 
              color="error"
              onClick={() => deleteLabel(tagVariation ?? 'event_category', selectedTag, () => submitCleanup())}
            >
              Yes, delete it
            </CabButton>
          </>
        }
      />
    </>
  );
};
  
export default OrganizationEventTags;